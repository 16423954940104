<template>
  <v-row no-gutters class="fill-height justify-center align-center">
    <v-col cols="auto">
      <v-progress-circular v-bind="{ color: 'primary', ...$attrs }" indeterminate></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MainLoader',
  inheritAttrs: false,
};
</script>
